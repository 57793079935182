<template>
  <div class="lang-switcher" :class="{ 'lang-switcher_open': isOpened }">
    <button type="button" class="lang-switcher__btn" @click="changeSwitcherState()">
      <img :src="currentLanguage.src" width="26" alt="" />
      <span>{{ currentLanguage.code }}</span>
    </button>
    <div class="lang-switcher__dropdown" v-if="isOpened">
      <span
        class="lang-switcher__item"
        v-for="lang in listLanguages"
        @click="changeLocale(lang.code)"
        :key="lang.code"
      >
        {{ lang.code }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "LanguageSwitcher",
  data: function () {
    return {
      isOpened: false,
      languages: [
        // { code: "de", src: require("@/assets/global/flags/de.svg") },
        { code: "en", src: require("@/assets/global/flags/en.svg") },
        // { code: "fr", src: require("@/assets/global/flags/fr.svg") },
        { code: "ru", src: require("@/assets/global/flags/ru.svg") },
      ],
    };
  },
  mounted() {
    document.addEventListener("click", this.clickHandler);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.clickHandler);
  },
  methods: {
    clickHandler(e) {
      if (!this.$el.contains(e.target)) {
        this.isOpened = false;
      }
    },
    changeSwitcherState() {
      this.isOpened = !this.isOpened;
    },
    changeLocale(locale) {
      this.$i18n.locale = locale;
      this.$router.replace(this.$switchLocalePath(locale));
    },
  },
  computed: {
    currentLanguage() {
      return this.languages.find((item) => item.code === this.$i18n.locale);
    },
    listLanguages() {
      return this.languages.filter((item) => item.code !== this.currentLanguage.code);
    },
  },
};
</script>

<style scoped lang="scss">
.lang-switcher {
  position: relative;
  width: 60px;

  &.lang-switcher_open {
    .dropdown-icon {
      transform: rotate(180deg);
    }
  }
}

.lang-switcher__btn {
  background: transparent;
  border: none;
  padding: 0;
  transition: all 0.2s ease-in-out;
  text-transform: uppercase;
  gap: 5px;
  font-size: 16px;
  color: $white;
  display: flex;
  align-items: center;

  img {
    border-radius: 50%;
  }

  span {
    line-height: 1;
  }
}

.lang-switcher__dropdown {
  position: absolute;
  top: 39px;
  left: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  width: 100%;
  padding: 10px 0;
  background: rgba($dark, 0.7);
  box-shadow: 0 0 30px rgba(125, 49, 234, 0.05);
  border-radius: 0 0 5px 5px;
}

.lang-switcher__item {
  text-align: center;
  width: 100%;
  cursor: pointer;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: $white;
  gap: 5px;

  img {
    border-radius: 50%;
  }

  &:hover {
    background: rgba($blue, 0.4);
  }
}

@include media-breakpoint-down(xl) {
  .lang-switcher__dropdown {
    top: 57px;
  }
}
</style>
