import Vue from "vue";
import VueRouter from "vue-router";
import EmptyLayout from "@/layouts/EmptyLayout.vue";
import BaseLayout from "@/layouts/BaseLayout.vue";
import { i18n } from "@/main";
import AuthLayout from "@/layouts/AuthLayout.vue";

Vue.use(VueRouter);

const routes = [
  { path: "/en", redirect: { path: "/" } },
  {
    path: "/:locale(ru)?",
    component: EmptyLayout,
    beforeEnter: languageMiddleware,
    children: [
      {
        path: "",
        component: BaseLayout,
        children: [
          {
            path: "",
            name: "Home",
            component: () => import("../views/HomeView.vue"),
          },
          {
            path: "about",
            name: "About",
            component: () => import("../views/AboutView.vue"),
          },
          {
            path: "platform",
            name: "Platform",
            component: () => import("../views/PlatformView.vue"),
          },
          {
            path: "market",
            name: "Market",
            component: () => import("../views/MarketView.vue"),
          },
          {
            path: "trade",
            name: "Trade",
            component: () => import("../views/TradeView.vue"),
          },
          {
            path: "tools",
            name: "Tools",
            component: () => import("../views/ToolsView.vue"),
          },
          {
            path: "strategies",
            name: "Strategies",
            component: () => import("../views/StrategiesView.vue"),
          },
          {
            path: "contacts",
            name: "Contacts",
            component: () => import("../views/ContactsView.vue"),
          },
          {
            path: "privacy",
            name: "Privacy",
            component: () => import("../views/PrivacyView.vue"),
          },
        ],
      },
      {
        path: "",
        component: AuthLayout,
        children: [
          {
            path: "activation/success",
            name: "Activation",
            component: () => import("../views/auth/ActivationView.vue"),
          },
          {
            path: "activation/error",
            name: "ActivationError",
            component: () => import("../views/auth/ActivationErrorView.vue"),
          },
          {
            path: "withdrawal/success",
            name: "Withdraw",
            component: () => import("../views/auth/WithdrawView.vue"),
          },
          {
            path: "withdrawal/error",
            name: "WithdrawError",
            component: () => import("../views/auth/WithdrawErrorView.vue"),
          },
        ],
      },

      {
        path: "auth",
        component: AuthLayout,
        children: [
          {
            path: "login",
            name: "Login",
            component: () => import("../views/auth/LoginView.vue"),
          },
          {
            path: "register",
            name: "Register",
            component: () => import("../views/auth/RegistrationView.vue"),
          },
          {
            path: "recovery",
            name: "PasswordReset",
            component: () => import("../views/auth/PasswordResetView.vue"),
          },
          {
            path: "forgot-password",
            name: "ForgotPassword",
            component: () => import("../views/auth/ForgotView.vue"),
          },
        ],
      },
    ],
  },
];

const router = new VueRouter({
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }

    return { x: 1, y: 0 };
  },
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;

function languageMiddleware(to, from, next) {
  const locale = to.params.locale || "en";

  i18n.locale = locale;

  document.querySelector("html").setAttribute("lang", locale);

  return next();
}

export const localePath = function (route, lang) {
  let locale = lang;

  if (locale === undefined) {
    locale = i18n.locale;
  }

  if (locale === "en") return route;
  return Object.assign(route, {
    params: {
      locale,
      ...route.params,
    },
  });
};

Vue.prototype.$localePath = localePath;

Vue.prototype.$switchLocalePath = function (lang) {
  const path = { ...router.currentRoute };

  let locale = lang;

  if (locale === undefined) {
    locale = i18n.locale;
  }

  if (locale === "en") locale = null;

  return Object.assign(path, {
    params: {
      locale,
    },
  });
};
